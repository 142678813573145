import cn from 'classnames';
import { forwardRef } from 'react';

const InputField = forwardRef(({
  value = "",
  placeholder = "",
  disabled = false,
  messageText = "",
  messageType = "",
  className = "",
  label = "",
  ...otherParams
}, ref) => {
  const classNames = cn([
    'input-field',
    {'input-field--alert': messageType === 'alert' || messageType === 'error'},
    // {'input-field--disabled': disabled}
  ])

  return (
    <div className="input-group">
      <div className={classNames}>
        {label &&
          <label className="input-field__label">{label}</label>
        }
        <input
          {...otherParams}
          ref={ref}
          className="input-field__input"
          value={value}
          disabled={disabled}
          placeholder={placeholder}
        />
        { messageText && <div className='field__message'>{ messageText }</div> }
      </div>
    </div>
  );
})

export default InputField;
