import { memo } from 'react';
import { Address } from '@/components/HeadlessCheckout/Address';
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext';

const ShippingAddress = () => {
  const { data, updateShippingAddress } = useHeadlessCheckoutContext()
  const shippingAddress = data?.cart?.shippingAddress

  return (
    <MemoizedShippingAddress
      shippingAddress={shippingAddress}
      shippingLines={data.availableShippingRates}
      updateShippingAddress={updateShippingAddress}
    />
  );
};

const MemoizedShippingAddress = memo(({
  shippingAddress,
  updateShippingAddress
}) => {
  const address = shippingAddress?.formatted[0]

  const handleCustomAddressChange = async (newAddress) => {
    if (newAddress && newAddress.city && newAddress.address1) {
      newAddress.country = 'US'
    }
    console.debug('filled in new address: ', newAddress);
    await updateShippingAddress(newAddress);
  }

  return (
    <div className="order-address">
      <Address
        address={address || ''}
        onChange={(value) => handleCustomAddressChange(value)}
      />
    </div>
  );
});

export default ShippingAddress