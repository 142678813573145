import { memo, useState } from 'react'
import { useCustomerContext } from '@/context/CustomerContext'
import { useModalContext } from '@/context/ModalContext'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { InputField } from '../InputField'
import IconCheckmark from '@/svgs/checkmark.svg'
import Checkbox from 'react-custom-checkbox'
import LoginAccountForm from '@/components/Forms/LoginAccountForm'
import ForgotPasswordForm from '@/components/Forms/ForgotPasswordForm'
import { GiftOrder } from '../GiftOrder'
import validator from 'validator'

/*
  2 things to know:
  - there is the customer context we get for logged in shopify customers
  - then there are customers that are associated to shopify's checkout

  We use these two objects to determine if customer is logged, if so, automatically
  add shopify customer to shopify checkout. If customer is not logged in, and
  a customer is added to shopify checkout, remove that customer. However, if
  customer is a guest and doesn't have a shopify account, they need to be added to
  shopify checkout.
*/

const Customer = ({ accountFormType = 'default' }) => {
  const { customer: data, logout } = useCustomerContext()
  const { data: cartData } = useHeadlessCheckoutContext()
  const modalContext = useModalContext()
  return (
    <MemoizedCustomer
      customer={data}
      cart={cartData.cart}
      logout={logout}
      modalContext={modalContext}
      accountFormType={accountFormType}
    />
  )
}

const MemoizedCustomer = memo(
  ({ customer, cart, logout, accountFormType: aft }) => {
    const [email, setEmail] = useState(customer?.email)
    const [validationError, setValidationError] = useState(null)
    const [acceptsMarketing, setAcceptsMarketing] = useState(false)
    const { updateOrderMetaData, addCustomerToOrder, removeCustomerFromOrder } =
      useHeadlessCheckoutContext()
    const { updateCustomer } = useCustomerContext()
    const [accountFormType, setAccountFormType] = useState(aft)
    const hasAssociatedEmail = cart?.buyerIdentity.email

    const addGuestCustomer = async () => {
      if (email) {
        if (validator.isEmail(email)) {
          // add order meta data to indicate guest
          await addCustomerToOrder({ email })
        } else {
          setValidationError('Invalid email format')
        }
      }
    }

    const removeCustomer = async () => {
      if (customer?.email) {
        await logout()
        await removeCustomerFromOrder()
      } else {
        await updateOrderMetaData([
          { key: 'is_guest_checkout', value: 'false' },
        ])
      }
      setValidationError(null)
      setEmail('')
    }

    const toggleAcceptsMarketing = async () => {
      await updateCustomer({ acceptsMarketing: !acceptsMarketing })
      setAcceptsMarketing(!acceptsMarketing)
    }

    const toggleIsGiftOrder = () => {
      updateOrderMetaData([
        {
          key: 'is_gift_order',
          value: cart.properties.is_gift_order == 'true' ? 'false' : 'true',
        },
      ])
    }

    const getAccountFormContent = (type) => {
      switch (type) {
        case 'guest':
          return (
            <InputField
              data-testid="guest-email-input"
              className="order-customer__email"
              placeholder="Email"
              type="email"
              name="email"
              autoComplete="email"
              messageType={validationError && 'alert'}
              messageText={validationError}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={addGuestCustomer}
            />
          )
        case 'login':
        case 'forgot_password':
        default:
          return (
            <>
              {getLoginFormContent(accountFormType)}
              <div className="divider">
                {/* <h3>OR</h3> -- can optionally add this back if we want to -- */}
              </div>
              <div>
                <h3>Continue As Guest</h3>
                <button
                  className="btn sitkablue"
                  onClick={() => setAccountFormType('guest')}
                  data-testid="guest-checkout-button"
                >
                  Guest Checkout
                </button>
              </div>
            </>
          )
      }
    }

    const getLoginFormContent = (type) => {
      switch (type) {
        case 'forgot_password':
          return <ForgotPasswordForm isCheckout />
        case 'login':
        default:
          return (
            <LoginAccountForm
              isCheckout
              onForgotPasswordClick={() =>
                setAccountFormType('forgot_password')
              }
            />
          )
      }
    }

    return (
      <div className="order-info">
        <div className="order-customer">
          <div
            className={`checkout__header checkout__header--border-on-closed checkout__row checkout__header--open`}
          >
            <h3>Customer Info</h3>
            <div className="order-customer__header-links">
              {hasAssociatedEmail && (
                <div className="order-customer__header-link">
                  Not you?
                  <button
                    data-testid={'customer-not-you-button'}
                    onClick={removeCustomer}
                    className="btn-link-underline"
                  >
                    {' Log out'}
                  </button>
                </div>
              )}
              {accountFormType === 'guest' && !hasAssociatedEmail && (
                <div className="order-customer__header-link">
                  Already have an account?
                  <button
                    data-testid={'already-have-account-button'}
                    onClick={() => setAccountFormType('login')}
                    className="btn-link-underline"
                  >
                    {' Log in'}
                  </button>
                </div>
              )}
            </div>
          </div>
          <>
            {hasAssociatedEmail ? (
              <div>{cart.buyerIdentity.email}</div>
            ) : (
              <div className={`order-customer-account-form ${accountFormType}`}>
                {getAccountFormContent(accountFormType)}
              </div>
            )}

            {customer?.email && (
              <div className="checkout__checkbox-wrapper">
                <Checkbox
                  className="checkout__checkbox"
                  icon={
                    <div className="checkbox--checked">
                      <IconCheckmark />
                    </div>
                  }
                  label={<h6>Subscribe to our newsletter (opt in)</h6>}
                  checked={acceptsMarketing}
                  onChange={toggleAcceptsMarketing}
                />
              </div>
            )}
            <div className="checkout__checkbox-wrapper">
              <Checkbox
                data-testid={'is-gift-order-checkbox'}
                className="checkout__checkbox"
                icon={
                  <div className="checkbox--checked">
                    <IconCheckmark />
                  </div>
                }
                label={
                  <h6>This order is a gift shipping directly to the recipient</h6>
                }
                checked={cart.properties.is_gift_order == 'true' ? true : false}
                onChange={toggleIsGiftOrder}
              />
            </div>
            {cart.properties.is_gift_order == 'true' && (
              <GiftOrder
                cart={cart}
                updateOrderMetaData={updateOrderMetaData}
              />
            )}
          </>
        </div>
      </div>
    )
  },
)

export default Customer
