import { useRouter } from 'next/router'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'

const ContinueShoppingButton = ({ data, isLink = false }) => {
  const router = useRouter()
  const { closeCartFlyout, setIsLoading, initializeCart } =
    useHeadlessCheckoutContext()

  const handleContinueShopping = async () => {
    if (!data?.cart) {
      setIsLoading(true)
      await initializeCart()
    }
    setIsLoading(false)
    closeCartFlyout()
    if (router.pathname === '/cart' || router.pathname === '/checkout') {
      router.push('/')
    }
  }

  return isLink ? (
    <button
      onClick={handleContinueShopping}
      className="checkout__continue-shopping-btn checkout__continue-shopping-link"
    >
      Continue Shopping
    </button>
  ) : (
    <button
      onClick={handleContinueShopping}
      className="checkout__continue-shopping-btn btn sitkablue"
    >
      Continue Shopping
    </button>
  )
}

export default ContinueShoppingButton
