import {
  Customer,
  ShippingLines,
  CheckoutButton,
  OrderSummary,
  OrderSummaryBreakdown,
  DiscountSection,
  TermsSection,
} from '@/components/HeadlessCheckout'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import ContinueShoppingButton from '../../ContinueShoppingButton/ContinueShoppingButton'

const StartStep = () => {
  const { data, cartFlyoutOpen } = useHeadlessCheckoutContext()

  return (
    <>
      <div className="checkout__layout checkout__main" role="main">
        <div className="checkout__panel-wrapper">
          <div className="checkout__panel">
            <OrderSummary />
          </div>
          <div className="checkout__panel">
            <DiscountSection />
          </div>
          <div className="checkout__panel checkout__terms-panel">
            <TermsSection />
          </div>
          <div className="checkout__panel">
            <OrderSummaryBreakdown readOnly={false} />
          </div>
        </div>
        <div className="checkout__panel-wrapper">
          {!cartFlyoutOpen && (
            <div className="checkout__panel shopping-btn-mobile">
             <ContinueShoppingButton data={data} isLink/>
            </div>
          )}
          <div className="checkout__panel">
            <Customer />
          </div>
          <div className="checkout__panel">
            <ShippingLines applicationLoading={false} />
          </div>
          <div className="checkout__navigation">
            <CheckoutButton />
          </div>
        </div>
      </div>
    </>
  )
}

export default StartStep
