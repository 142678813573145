import { InputField } from '../InputField';
import classNames from 'classnames';
import { useState } from 'react';
import { usePlacesWidget } from "react-google-autocomplete";

export const Address = ({
  address,
  onChange,
}) => {
  const [searchedAddress, setSearchedAddress] = useState(address || '')

  const { ref: placesWidgetRef } = usePlacesWidget({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const { address_components } = place;
      if (address_components) {
        let address1 = '';
        let city = '';
        let province = '';
        let zip = '';

        address_components.forEach(c => {
          if (c.types.includes('street_number')) {
            address1 += c.long_name;
          } else if (c.types.includes('route')) {
            if (address1.length > 0) address1 += " ";
            address1 += c.long_name;
          } else if (c.types.includes('locality') || c.types.includes('sublocality_level_1')) {
            city = c.long_name;
          } else if (c.types.includes('administrative_area_level_1')) {
            province = c.short_name;
          } else if (c.types.includes('postal_code')) {
            zip = c.long_name;
          }
        })

        onChange({
          address1,
          city,
          province,
          zip,
        })

        setSearchedAddress(null)
      }
    },
    options: {
      types: ['address'],
      fields: ['address_components'],
      componentRestrictions: { country: "us" },
    },
    inputAutocompleteValue: true
  });

  return (
    <div className={classNames['address-form']}>
      <InputField
        ref={placesWidgetRef}
        type="search"
        value={searchedAddress === null ? address : searchedAddress}
        placeholder="Search for Your Address"
        autoComplete="off"
        className="input"
        onChange={(e) => setSearchedAddress(e.target.value)}
      />
    </div>
  );
};
